import _ from "lodash";
import { NodePositions, ThemeColorsEnum } from "../constants/enums";

import dayjs from "dayjs";
import Enumerable from "linq";
import { toast as toastify } from "react-toastify";

const appendScript = (scriptToAppend, position = NodePositions.Append) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = scriptToAppend;
  // script.async = true;
  if (position === NodePositions.Prepend) {
    const rootNode = document.getElementById("root");
    rootNode.after(script);
  } else document.body.appendChild(script);

  return script;
};

const appendStyleSheet = (cssToAppend, position = NodePositions.Append) => {
  const css = document.createElement("link");
  css.type = "text/css";
  css.rel = "stylesheet";
  css.href = cssToAppend;
  // script.async = true;
  if (position === NodePositions.Prepend) {
    const firstLinkNode = document.querySelector("[type='text/css']");
    document.head.insertBefore(css, firstLinkNode);
    // document.head.insertAdjacentElement("afterbegin", css);
    // document.head.insertAdjacentElement("beforebegin", css);
  } else document.head.appendChild(css);

  return css;
};

const clearCache = () => {
  // window.history.forward(1);
  caches.keys().then((names) => {
    names.forEach((name) => {
      caches.delete(name);
    });
  });
};

const dayNameFromIndex = (index) => {
  switch (index) {
    case 0:
      return "Sunday";
    case 1:
      return "Monday";
    case 2:
      return "Tuesday";
    case 3:
      return "Wednesday";
    case 4:
      return "Thursday";
    case 5:
      return "Friday";
    case 6:
      return "Saturday";

    default:
      return "";
  }
};

const formatDateAndTime = (dateTime) =>
  dayjs(dateTime).format("ddd MMM D @ H:mm");

const formatFullDate = (dateTime) => dayjs(dateTime).format("ddd MMM D, YYYY");

const formatFullDateAndTime = (dateTime) =>
  dayjs(dateTime).format("ddd MMM D, YYYY @ H:mm");

// const formatISODate = (dateTime) => dayjs(new Date(dateTime), "YYYY-MM-DD");
const formatISODate = (dateTime) =>
  dayjs(new Date(dateTime)).format("YYYY-MM-DD");

const getCustomerPredictions = (customers, search) => {
  const matches = Enumerable.from(customers)
    .where(
      (x) =>
        x.company.toLowerCase().includes(search) ||
        (x.contactName && x.contactName?.toLowerCase().includes(search)) ||
        (x.email && x.email?.toLowerCase().includes(search)) ||
        (x.ltd && x.ltd?.toLowerCase().includes(search)) ||
        (x.memberNumber && x.memberNumber?.toLowerCase().includes(search)) ||
        (x.phone && x.phone?.toLowerCase().includes(search)) ||
        (x.permanentInstructions &&
          x.permanentInstructions?.toLowerCase().includes(search)) ||
        (x.serialNumber && x.serialNumber?.toLowerCase().includes(search))
      // x.company.toLowerCase().includes(search) ||
      // search.includes(x.company.toLowerCase()) ||
      // (x.contactName &&
      //   (x.contactName?.toLowerCase().includes(search) ||
      //     search.includes(x.contactName?.toLowerCase()))) ||
      // (x.email &&
      //   (x.email?.toLowerCase().includes(search) ||
      //     search.includes(x.email?.toLowerCase()))) ||
      // (x.ltd &&
      //   (x.ltd?.toLowerCase().includes(search) ||
      //     search.includes(x.ltd?.toLowerCase()))) ||
      // (x.memberNumber &&
      //   (x.memberNumber?.toLowerCase().includes(search) ||
      //     search.includes(x.memberNumber?.toLowerCase()))) ||
      // (x.phone &&
      //   (x.phone?.toLowerCase().includes(search) ||
      //     search.includes(x.phone?.toLowerCase()))) ||
      // (x.permanentInstructions &&
      //   (x.permanentInstructions?.toLowerCase().includes(search) ||
      //     search.includes(x.permanentInstructions?.toLowerCase()))) ||
      // (x.serialNumber &&
      //   (x.serialNumber?.toLowerCase().includes(search) ||
      //     search.includes(x.serialNumber?.toLowerCase())))
    )
    .distinct((x) => x.id)
    .toArray();

  return matches;
};

const getDataForUserPicker = (usersByBranch) => {
  try {
    const finalData = [];

    //TODO: refactor this, not how you're supposed to use map
    usersByBranch?.map((data) => {
      const branchName = data.group?.name;
      const existing = Enumerable.from(finalData).firstOrDefault(
        (x) => x.label === branchName
      );

      if (!existing) {
        finalData.push({
          label: branchName,
          options: [{ value: data.value, label: data.text }],
        });
      } else {
        existing.options = [
          ...existing.options,
          { value: data.value, label: data.text },
        ];
      }
      return 0;
    });

    return finalData;
  } catch (err) {
    console.error(err);
    return [];
  }
};

const isProduction = () => {
  return process.env.REACT_APP_ENV === "prod";
};

const removeScript = (scriptToRemove) => {
  document.body.removeChild(scriptToRemove);
};

const removeStyleSheet = (cssToRemove) => {
  document.head.removeChild(cssToRemove);
};

const sanitizePaginationUrl = (url) => {
  if (url === null) return null;
  return new URL(url).search;
};

const toast = (
  message,
  color = ThemeColorsEnum.default,
  response = null,
  fillColor = false,
  config = {}
) => {
  const toastConfig = { ...config, ...(fillColor ? { theme: "colored" } : {}) };

  if (_.isString(message) && !message.endsWith(".")) message = `${message}.`;
  if (response?.data?.message) message = `${message}\n${response.data.message}`;
  else if (response?.data?.errors)
    message = `${message}\n\n  - ${response.data.errors.join("\n  - ")}`;

  switch (color) {
    case ThemeColorsEnum.danger:
    case ThemeColorsEnum.error:
      toastify.error(message, toastConfig);
      break;

    case ThemeColorsEnum.info:
      toastify.info(message, toastConfig);
      break;

    case ThemeColorsEnum.success:
      toastify.success(message, toastConfig);
      break;

    case ThemeColorsEnum.warning:
      toastify.warn(message, toastConfig);
      break;

    default:
      toastify(message, { ...toastConfig, theme: "dark" });
      break;
  }
};

const truncateString = (str, length, ending) => {
  if (!str) return "";

  if (length == null) length = 100;

  if (ending == null) ending = "...";

  if (str.length > length)
    return str.substring(0, length - ending.length) + ending;
  else return str;
};

//------------------------------------------------
const utilityService = {
  appendScript,
  appendStyleSheet,
  clearCache,
  dayNameFromIndex,
  formatDateAndTime,
  formatFullDate,
  formatFullDateAndTime,
  formatISODate,
  getCustomerPredictions,
  getDataForUserPicker,
  isProduction,
  removeScript,
  removeStyleSheet,
  sanitizePaginationUrl,
  toast,
  truncateString,
};

export default utilityService;
//------------------------------------------------
