//import { LoadingIconTypes } from "../constants/misc";
import React from "react";
import logo from "../assets/img/spinners/ripple.gif";

//export const LoadingIconTypes = {
//  // https://loading.io/
//  Spinner: process.env.REACT_APP_PUBLIC_URL + "/assets/img/spinners/spinner.gif",
//  Pulse: process.env.REACT_APP_PUBLIC_URL + "/assets/img/spinners/pulse.gif",
//  Wedges: process.env.REACT_APP_PUBLIC_URL + "/assets/img/spinners/wedges.gif",
//  Ripple: process.env.REACT_APP_PUBLIC_URL + "/assets/img/spinners/ripple.gif",
//};

const CustomSpinner = ({ isActive, type, ...props }) => {
  type = logo;

  return (
    <div className={`overlay-spinner-container ${isActive ? "" : "hidden"}`}>
      <img
        src={type}
        className={`overlay-spinner ${props.className}`}
        alt="loading..."
        style={{
          //height: "100px",
          //width: "100px",
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          ...props.style,
        }}
      />
    </div>
  );
};

export default CustomSpinner;
