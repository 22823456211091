import React, { useContext, useEffect, lazy, Suspense } from "react";
import { Navigate, Routes } from "react-router-dom";

import { Route } from "react-router";
import { AppRoutes } from "../../constants/appRoutes";
import { StatusEnum } from "../../constants/enums";
import { LocalStorageKeys } from "../../constants/storageKeys";
import { AuthContext } from "../../context/contextProvider";
import useAlerts from "../../hooks/useAlerts";
import useQuery from "../../hooks/useQuery";

import Services from "../../services/services";
import NavigateToLogin from "./NavigateToLogin";
import PrivacyPage from "../../pages/Privacy";
import { useEffectAsync } from "../../hooks/useEffectAsync";
import CustomSpinner from "../../components/CustomSpinner";

/******** Lazy imports ************/
const DashboardPage = lazy(() =>  import("../../pages/DashboardPage"));
const TestPage = lazy(() => import("../../pages/TestPage"));
const UserGuidesPage = lazy(() => import("../../pages/UserGuidesPage"));
const ModulesPage = lazy(() => import("../../pages/admin/ModulesPage"));
const SettingsPage = lazy(() => import("../../pages/admin/SettingsPage"));
const AlertCreatePage = lazy(() => import("../../pages/admin/alerts/AlertCreatePage"));
const AlertEditPage = lazy(() => import("../../pages/admin/alerts/AlertEditPage"));
const AlertsPage = lazy(() => import("../../pages/admin/alerts/AlertsPage"));
const ForgotPasswordPage = lazy(() => import("../../pages/auth/ForgotPasswordPage"));
const LoginPage = lazy(() => import("../../pages/auth/LoginPage"));
const ResetPasswordPage = lazy(() => import("../../pages/auth/ResetPasswordPage"));
const BranchUsersDetailsPage = lazy(() => import("../../pages/billing/BranchUsersDetailsPage"));
const ClientBranchesPage = lazy(() => import("../../pages/billing/ClientBranchesPage"));
const EnrolledClientsPage = lazy(() => import("../../pages/billing/EnrolledClientsPage"));
const EnrolledUsersPage = lazy(() => import("../../pages/billing/EnrolledUsersPage"));
const TrialUsersPage = lazy(() => import("../../pages/billing/TrialUsersPage"));
const BranchCreatePage = lazy(() => import("../../pages/branches/BranchCreatePage"));
const BranchEditPage = lazy(() => import("../../pages/branches/BranchEditPage"));
const BranchesPage = lazy(() => import("../../pages/branches/BranchesPage"));
const CustomerCreatePage = lazy(() => import("../../pages/customers/CustomerCreatePage"));
const CustomerEditPage = lazy(() => import("../../pages/customers/CustomerEditPage"));
const CustomerLocationAddPage = lazy(() => import("../../pages/customers/CustomerLocationAddPage"));
const CustomerLocationEditPage = lazy(() => import("../../pages/customers/CustomerLocationEditPage"));
const CustomerLocationsPage = lazy(() => import("../../pages/customers/CustomerLocationsPage"));
const CustomersImportPage = lazy(() => import("../../pages/customers/CustomersImportPage"));
const CustomersMapPage = lazy(() => import("../../pages/customers/CustomersMapPage"));
const CustomersPage = lazy(() => import("../../pages/customers/CustomersPage"));
const DeliveryOrderEditPage = lazy(() => import("../../pages/deliveryOrders/DeliveryOrderEditPage"));
const DeliveryOrdersActivePage = lazy(() => import("../../pages/deliveryOrders/DeliveryOrdersActivePage"));
const DeliveryOrdersMapPage = lazy(() => import("../../pages/deliveryOrders/DeliveryOrdersMapPage"));
const DirectDropCreatePage = lazy(() => import("../../pages/directDrops/DirectDropCreatePage"));
const DirectDropEditPage = lazy(() => import("../../pages/directDrops/DirectDropEditPage"));
const DirectDropsActivePage = lazy(() => import("../../pages/directDrops/DirectDropsActivePage"));
const DirectDropsCompletedPage = lazy(() => import("../../pages/directDrops/DirectDropsCompletedPage"));
const DirectDropsMapPage = lazy(() => import("../../pages/directDrops/DirectDropsMapPage"));
const ForbiddenPage = lazy(() => import("../../pages/errors/ForbiddenPage"));
const NotFoundPage = lazy(() => import("../../pages/errors/NotFoundPage"));
const ProductCreatePage = lazy(() => import("../../pages/products/ProductCreatePage"));
const ProductEditPage = lazy(() => import("../../pages/products/ProductEditPage"));
const ProductsPage = lazy(() => import("../../pages/products/ProductsPage"));
const DispatchesReportsPage = lazy(() => import("../../pages/reports/DispatchesReportsPage"));
const ServicesReportsPage = lazy(() => import("../../pages/reports/ServicesReportsPage"));
const ScheduledOrderCreatePage = lazy(() => import("../../pages/schedules/ScheduledOrderCreatePage"));
const ScheduledOrderEditPage = lazy(() => import("../../pages/schedules/ScheduledOrderEditPage"));
const ScheduledOrdersPage = lazy(() => import("../../pages/schedules/ScheduledOrdersPage"));
const ServiceOrderCategoriesPage = lazy(() => import("../../pages/serviceOrderCategories/ServiceOrderCategoriesPage"));
const ServiceOrderCategoryCreatePage = lazy(() => import("../../pages/serviceOrderCategories/ServiceOrderCategoryCreatePage"));
const ServiceOrderCategoryEditPage = lazy(() => import("../../pages/serviceOrderCategories/ServiceOrderCategoryEditPage"));
const ServiceOrderCreatePage = lazy(() => import("../../pages/serviceOrders/ServiceOrderCreatePage"));
const ServiceOrderEditPage = lazy(() => import("../../pages/serviceOrders/ServiceOrderEditPage"));
const ServiceOrdersActivePage = lazy(() => import("../../pages/serviceOrders/ServiceOrdersActivePage"));
const ServiceOrdersCompletedPage = lazy(() => import("../../pages/serviceOrders/ServiceOrdersCompletedPage"));
const ServiceOrdersMapPage = lazy(() => import("../../pages/serviceOrders/ServiceOrdersMapPage"));
const TattleCreatePage = lazy(() => import("../../pages/tattles/TattleCreatePage"));
const TattleEditPage = lazy(() => import("../../pages/tattles/TattleEditPage"));
const TattlesPage = lazy(() => import("../../pages/tattles/TattlesPage"));
const TruckCreatePage = lazy(() => import("../../pages/trucks/TruckCreatePage"));
const TruckEditPage = lazy(() => import("../../pages/trucks/TruckEditPage"));
const TrucksPage = lazy(() => import("../../pages/trucks/TrucksPage"));

const UserCreatePage = lazy(() => import("../../pages/users/UserCreatePage"));
const UserEditPage = lazy(() => import( "../../pages/users/UserEditPage"));
const UsersMapPage = lazy(() => import("../../pages/users/UsersMapPage"));
const UsersPage = lazy(() => import("../../pages/users/UsersPage"));
const ScheduledCheckInsPage = lazy(() => import("../../pages/workAlone/ScheduledCheckInsPage"));
const ContactPage = lazy(() => import("../../pages/ContactPage"));
const DeliveryOrderCreatePage = lazy(() => import("../../pages/deliveryOrders/DeliveryOrderCreatePage"));
const DeliveryOrdersCompletedPage = lazy(() => import("../../pages/deliveryOrders/DeliveryOrdersCompletedPage"));

const NavigationContainer = (props) => {
  const {
    currentUser,
    setCurrentUser,
    currentClient,
    setCurrentClient,
    setBranchModules,
    setBranchSettings,
    setUserSettings,
  } = useContext(AuthContext);


  const query = useQuery();
  useAlerts();

  useEffectAsync(async () => {
    const token = query?.get("access_token");
    if (token) {
      await processAccessTokenAsync(token);
    }
  }, [query]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffectAsync( async () => {
    if (currentUser) await initAsync();
  }, [currentUser, currentClient]); // eslint-disable-line react-hooks/exhaustive-deps

  const initAsync = async () => {
    // setLoading(true);
    const response = await Services.api.utility.initialize();
    // setLoading(false);

    setBranchModules(response.data?.branchModules);
    setBranchSettings(response.data?.branchSettings);
    setUserSettings(response.data?.userSettings);
  };

  const processAccessTokenAsync = async (accessToken) => {
    Services.localStorage.setProperty(
      LocalStorageKeys.accessToken,
      accessToken
    );
    const response = await Services.api.account.me();

    if (response.ok) {
      setCurrentUser(response.data);
      setCurrentClient(response.data?.primaryClient);
    }
  };

  return (
    // <Router forceRefresh={false}> // moved to App.js
    <Routes>
      <Route
        exact
        path={AppRoutes.dashboard}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DashboardPage /></Suspense> : <NavigateToLogin />}
      />
      {/* Admin */}
      <Route
        exact
        path={AppRoutes.modules}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ModulesPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.settings}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><SettingsPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.alerts}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><AlertsPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.alerts_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><AlertCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.alerts_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><AlertEditPage /></Suspense> : <NavigateToLogin />}
      />

      {/* Billing */}
      <Route
        exact
        path={AppRoutes.billingClients}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><EnrolledClientsPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.billingClientBranches}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ClientBranchesPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.billingBranchDetails}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><BranchUsersDetailsPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.billingUsers}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><EnrolledUsersPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.billingTrialUsers}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><TrialUsersPage /></Suspense> : <NavigateToLogin />}
      />

      {/* Branches */}
      <Route
        exact
        path={AppRoutes.branches}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><BranchesPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.branches_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><BranchCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.branches_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><BranchEditPage /> </Suspense> : <NavigateToLogin />}
      />

      {/* Contact */}
      
	  <Route
        exact
        path={AppRoutes.contact}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}> <ContactPage /> </Suspense> : <NavigateToLogin />}
      />

      {/* Customers */}
      <Route
        exact
        path={AppRoutes.customers}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><CustomersPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customers_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><CustomerCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customers_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><CustomerEditPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customers_import}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><CustomersImportPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customers_map}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><CustomersMapPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customerLocations}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><CustomerLocationsPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.customerLocations_edit}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><CustomerLocationEditPage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.customerLocations_add}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><CustomerLocationAddPage /></Suspense> : <NavigateToLogin />
        }
      />
      {/* Delivery orders */}
      <Route
        exact
        path={AppRoutes.deliveryOrders}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DeliveryOrdersActivePage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.deliveryOrders_completed}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true}/>}><DeliveryOrdersCompletedPage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.deliveryOrders_create}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true}/>}><DeliveryOrderCreatePage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.deliveryOrders_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DeliveryOrderEditPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.deliveryOrders_map}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DeliveryOrdersMapPage /></Suspense> : <NavigateToLogin />}
      />
      {/* Direct drops */}
      <Route
        exact
        path={AppRoutes.directDrops}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DirectDropsActivePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.directDrops_completed}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DirectDropsCompletedPage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.directDrops_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DirectDropCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.directDrops_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DirectDropEditPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.directDrops_map}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DirectDropsMapPage /></Suspense> : <NavigateToLogin />}
      />
      {/* Login */}
      <Route
        exact
        path={AppRoutes.login}
        element={
          currentUser ? <Navigate to={AppRoutes.dashboard} /> : <LoginPage />
        }
      />
      {/* Password Reset */}
      <Route
        exact
        path={AppRoutes.forgotPassword}
        element={
          currentUser ? (
            <Navigate to={AppRoutes.dashboard} />
          ) : (
            <ForgotPasswordPage />
          )
        }
      />
      <Route
        exact
        path={AppRoutes.resetPassword}
        element={
          currentUser ? (
            <Navigate to={AppRoutes.dashboard} />
          ) : (
            <ResetPasswordPage />
          )
        }
      />
      {/* Products */}
      <Route
        exact
        path={AppRoutes.products}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ProductsPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.products_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ProductCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.products_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ProductEditPage /></Suspense> : <NavigateToLogin />}
      />

      {/* Reports */}
      <Route
        exact
        path={AppRoutes.reports_dispatches}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><DispatchesReportsPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.reports_services}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServicesReportsPage /></Suspense> : <NavigateToLogin />}
      />

      {/* Scheduled orders */}
	  <Route
		forceRefresh={true}
		path={AppRoutes.scheduledOrders}
		element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ScheduledOrdersPage /></Suspense> : <NavigateToLogin/>}
	  />
      <Route
        //exact
		forceRefresh={true}
        path={AppRoutes.scheduledOrders_delivery}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ScheduledOrdersPage /></Suspense> : <NavigateToLogin />}
      />
	  <Route
        //exact
		forceRefresh={true}
        path={AppRoutes.scheduledOrders_direct}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ScheduledOrdersPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        //exact
		forceRefresh={true}
        path={AppRoutes.scheduledOrders_service}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ScheduledOrdersPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.scheduledOrders_create}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ScheduledOrderCreatePage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.scheduledOrders_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ScheduledOrderEditPage /></Suspense> : <NavigateToLogin />}
      />

      {/* Service orders */}
      <Route
        exact
        path={AppRoutes.serviceOrders}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServiceOrdersActivePage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.serviceOrders_completed}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServiceOrdersCompletedPage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.serviceOrders_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServiceOrderCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.serviceOrders_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServiceOrderEditPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.serviceOrders_map}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServiceOrdersMapPage /></Suspense> : <NavigateToLogin />}
      />

      {/* Service order categories */}
      <Route
        exact
        path={AppRoutes.serviceOrderCategories}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServiceOrderCategoriesPage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.serviceOrderCategories_create}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServiceOrderCategoryCreatePage /></Suspense> : <NavigateToLogin />
        }
      />
      <Route
        exact
        path={AppRoutes.serviceOrderCategories_edit}
        element={
          currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><ServiceOrderCategoryEditPage /></Suspense> : <NavigateToLogin />
        }
      />

      {/* Tattles */}
      <Route
        exact
        path={AppRoutes.tattles}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><TattlesPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.tattles_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><TattleCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.tattles_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><TattleEditPage /></Suspense> : <NavigateToLogin />}
      />

      {/* Trucks */}
      <Route
        exact
        path={AppRoutes.trucks}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><TrucksPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.trucks_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><TruckCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.trucks_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><TruckEditPage /></Suspense> : <NavigateToLogin />}
      />

      {/* User Guides */}
      <Route
        exact
        path={AppRoutes.userGuides}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><UserGuidesPage /></Suspense> : <NavigateToLogin />}
      />

      {/* Users */}
      <Route
        exact
        path={AppRoutes.users}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><UsersPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.users_create}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><UserCreatePage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.users_deleted}
        element={
          currentUser ? (
            <UsersPage status={StatusEnum.deleted} />
          ) : (
            <NavigateToLogin />
          )
        }
      />
      <Route
        exact
        path={AppRoutes.users_edit}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true}/>}><UserEditPage /></Suspense> : <NavigateToLogin />}
      />
      <Route
        exact
        path={AppRoutes.users_map}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true}/>}><UsersMapPage /></Suspense> : <NavigateToLogin />}
      />

      {/* WorkAlone */}
      <Route
        exact
        path={AppRoutes.scheduledCheckIns}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true}/>}> <ScheduledCheckInsPage /> </Suspense> : <NavigateToLogin />}
      />

      <Route
        exact
        path={AppRoutes.test}
        element={currentUser ? <Suspense fallback={<CustomSpinner isActive={true} />}><TestPage /></Suspense> : <NavigateToLogin />}
      />

      <Route path={AppRoutes.forbidden} element={<ForbiddenPage />} />
      <Route path="*" element={<NotFoundPage />} />
	  <Route
		exact
		path={AppRoutes.privacy} 
		element={<PrivacyPage />} />
    </Routes>
    // </Router>
  );
};

export default NavigationContainer;
